<template>
  <NuxtLink
    v-if="Array.isArray(data.link) && data.link.length"
    :to="
      data.link[0].internalLink !== null
        ? localePath(`/${data.link[0].internalLink!.slug}`)
        : (data.link[0].externalLink as string)
    "
    :target="data.link[0].externalLink ? '_blank' : undefined"
  >
    <div class="h-[52%] bg-neutral-50 p-6 md:h-[50%] md:px-10 md:py-9">
      <div v-if="data.title" class="mb-4 text-heading-25-medium-mobile md:text-heading-22-medium">
        <span
          class="block w-full origin-left transform-gpu transition-all duration-1000"
          :class="isActive ? 'md:scale-125' : 'md:scale-100'"
          v-html="data.title"
        ></span>
      </div>
      <Type v-if="data.html" :level="4" class="overflow-hidden">
        <div
          class="line-clamp-6 transform-gpu transition-all"
          :class="isActive ? 'opacity-100 delay-600 duration-1000' : 'opacity-0 duration-100'"
          v-html="data.html"
        ></div>
      </Type>
    </div>
    <Picture
      class="flex h-[48%] !w-full transform-gpu overflow-hidden rounded-b-4xl bg-neutral-50 text-center transition-all duration-500 ease-quart-in-out md:h-[50%] md:pb-10"
      :class="isActive ? 'lg:rounded-b-[70px]' : ' md:rounded-b-4xl'"
      sizes="(min-width: 2060px) 440px, calc(20.48vw + 22px)"
      pictureClass="w-full h-full pb-18 md:pb-0"
      imgClass="mx-auto w-full self-center h-full object-contain object-center max-w-[440px]"
      :data="data?.image!"
      loading="lazy"
    />
    <span
      class="absolute bottom-6 right-0 flex w-fit items-center px-6 text-right text-button-20-regular font-medium md:bottom-10 md:right-10 md:px-0 md:text-button-22-regular"
    >
      <span class="mr-3.5 inline-block"
        ><span
          class="inline-block transform-gpu transition-all duration-500 ease-quart-in-out"
          :class="isActive ? 'opacity-100 delay-400' : 'opacity-0'"
          >{{ data.link[0].label }}</span
        ></span
      >
      <Icon
        :name="isActive ? 'circle-chevron-right-general' : 'circle-plus-general'"
        class="h-8px w-8px"
    /></span>
  </NuxtLink>
</template>

<script setup lang="ts">
import { CardRecord } from '~/types/generated'

const localePath = useLocalePath()

interface CardProps {
  data: CardRecord
  isActive: boolean
}

withDefaults(defineProps<CardProps>(), {
  isActive: false
})
</script>
