<template>
  <div class="px-page my-20 md:my-40">
    <div class="grid-default">
      <div class="col-span-12 md:col-span-4 md:col-start-8">
        <Heading v-if="data.title" generic :size="3" class="mb-10 md:mb-16">{{
          data.title
        }}</Heading>
      </div>
      <div
        ref="cardsRef"
        class="col-span-12 h-[var(--swiper-cards-height-mobile)] md:h-[540px] xl:h-[686px]"
      >
        <LazySwiperCards v-if="isMobile && isInteractive" :data="data.cards" class="md:!hidden" />
        <div v-if="isDesktop" class="gap-x-default hidden h-full w-full justify-between md:flex">
          <Card
            v-for="(item, idx) in data.cards"
            :key="idx"
            :data="item"
            class="relative transform-gpu transition-all duration-1000"
            :class="[
              activeIdx === idx ? 'h-full flex-[1.5]' : 'h-[27rem] flex-1',
              !isInteractive && 'pointer-events-none'
            ]"
            :isActive="activeIdx === idx"
            @mouseenter="activeIdx = idx"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionCardsSliderRecord } from '~/types/generated'

const { isMobile, isDesktop } = useDevice()

defineProps({
  data: {
    type: Object as PropType<SectionCardsSliderRecord>,
    default: () => {}
  }
})

const cardsRef = ref<HTMLElement | null>(null)
const activeIdx = ref(-1)
const isInteractive = ref(false)

const { stop } = useIntersectionObserver(
  cardsRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting && activeIdx.value === -1) {
      activeIdx.value = 0

      useTimeoutFn(
        () => {
          isInteractive.value = true
        },
        isDesktop ? 800 : 0
      )
    }
  },
  {
    threshold: 0.75
  }
)

onUnmounted(() => {
  stop()
})
</script>
